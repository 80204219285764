@import 'src/styles/config';
.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background-color: $color-grey-dark;
  min-height: calc(var(--vh, 1vh) * 100);
  & > * {
    min-width: 0;
  }
}

@media (min-width: map-get($breakpoints, 'lg')  + px) {
}
