@import 'src/styles/config';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  flex: 1 1 auto;
  height: 100%;
}

.card {
  max-width: 32rem;
  width: 100%;
  padding: 2rem;
}
