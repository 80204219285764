@import 'src/styles/config';
.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  background-color: $color-grey-light-2;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 -1.6rem;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &Logo {
    width: 6.4rem;
  }
  &Text {
    margin-top: 1.2rem;
    width: 12.2rem;
  }
}
.title {
  margin-top: 3.5rem;
}

.form {
  max-width: 32rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.4rem 0;
}
//
//@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
//  .header {
//    &Logo {
//      width: 8rem;
//    }
//    &Text {
//      margin-top: 1.4rem;
//      width: 14.5rem;
//    }
//  }
//  .title {
//    margin-top: 3.8rem;
//  }
//}
//
//@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
//  .header {
//    &Logo {
//      width: 7rem;
//    }
//    &Text {
//      margin-top: 1.2rem;
//      width: 13.5rem;
//    }
//  }
//  .title {
//    margin-top: 3.2rem;
//  }
//}
//
//@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
//  .header {
//    &Logo {
//      width: 6rem;
//    }
//    &Text {
//      margin-top: 1rem;
//      width: 12rem;
//    }
//  }
//  .title {
//    margin-top: 3rem;
//  }
//}

@media (min-width: map-get($breakpoints, 'md') + px) {
  .root {
    position: relative;
    z-index: 1;
  }
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 160rem;
    width: 100%;
    margin: 0 auto;
    z-index: -1;
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 1.6rem;
      transform: translateY(-50%);
      margin: 8rem 0 0;
      height: 55.9rem;
      width: 60.8rem;
      background: url('../../images/bg-login.png') no-repeat;
    }
  }
  .header {
    &Logo {
      width: 8.6rem;
    }
    &Text {
      width: 16.2rem;
      margin-top: 1.6rem;
    }
  }
  .title {
    margin-top: 6.1rem;
  }
}

@media (min-width: map-get($breakpoints, 'xl') + px) {
  .bg {
    &::before {
      left: 6rem;
    }
  }
}
