@import 'src/styles/config';

.root {
  border-bottom: 0.1rem solid rgba-to-rgb(rgba($color-secondary, 0.2));
  position: sticky;
  top: 0;
  background-color: $color-grey-dark;
  z-index: 100;
}
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
