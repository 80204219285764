@import 'src/styles/config';
.root {
  padding: 1rem 0.5rem 0.9rem 0.5rem;
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  color: rgba($color-secondary, 0.5);
}
.inner {
}
.active {
  color: $color-primary;
}
.icon {
  line-height: 0;
}
.title {
  margin-top: 0.25rem;
  font-size: 1rem;
}
