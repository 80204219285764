@import 'src/styles/config';

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  height: 100%;
}
body {
  min-height: 100%;
}
#root {
  min-height: 100%;
}

.container {
  width: 100%;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 120.6rem;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}
