.root {
  display: grid;
  grid-template-areas:
    'icon icon-text'
    'icon text';
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  & > & {
    min-width: 0;
  }
}
.icon {
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconText {
  grid-area: icon-text;
}
.text {
  grid-area: text;
}
