.root {
  &Header {
    justify-content: flex-end;
    flex-grow: 1;
  }
}
.item {
  &Root {
    flex-grow: 0;
    padding: 0.95rem 1rem;
  }
  &Inner {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
  }
  &Title {
    margin: 0 0 0 1rem;
    font-size: 1.2rem;
  }
}
